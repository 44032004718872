@font-face {
  font-family: "SofiaProRegular";
  font-style: normal !important;
  font-weight: normal !important;
  src: local("Sofia Pro"), local("SofiaPro"),
    /* url("./assets/fonts/SofiaProBlackItalic_0.otf"),
       url("./assets/fonts/SofiaProBlack_0.otf"),
       url("./assets/fonts/SofiaProBoldItalic_0.otf"),
       url("./assets/fonts/SofiaProExtraLight_0.otf"),
       url("./assets/fonts/SofiaProItalic_0.otf"),
       url("./assets/fonts/SofiaProLightItalic_0.otf"),
       url("./assets/fonts/SofiaProLight_0.otf"),
       url("./assets/fonts/SofiaProMediumItalic_0.otf"),
       url("./assets/fonts/SofiaProMedium_0.otf"),
       url("./assets/fonts/SofiaProSemiBoldItalic_0.otf"),
       url("./assets/fonts/SofiaProSemiBold_0.otf"),
       url("./assets/fonts/SofiaProUltraLightItalic_0.otf"),
       url("./assets/fonts/SofiaProUltraLight_0.otf"), */
      url("./assets/fonts/SPR.otf");
}

@font-face {
  font-family: "SofiaProBold";
  font-style: normal;
  src: local("Sofia Pro"), local("SofiaPro"),
    url("./assets/fonts/SofiaProBold_0.otf");
}

@font-face {
  font-family: "SofiaProMedium";
  font-style: normal;
  src: local("Sofia Pro"), local("SofiaPro"),
    url("./assets/fonts/SofiaProMedium_0.otf");
}

body {
  margin: 0;
  background-color: #fafafa;
  height: 100vh;
  font-family: "SofiaProRegular" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100vh;
  --antd-wave-shadow-color: none !important;
}

#root {
  height: 100%;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
