@keyframes background {
    from {
        background: #f8f9fa;
    }
    to {
        background: #f8f9fa;
    }
}

/* 
.ant-layout-sider-children::-webkit-scrollbar {
    display: none;
}

.ant-layout-sider-children {
    -ms-overflow-style: none;
    scrollbar-width: none;
} */
.ant-layout-content {
    border-radius: 8px;
    border: 1px solid #dde2e5 !important;
    background-color: #ffffff;
    height: 100%;
    margin-top: 64px;
}
.ant-layout {
    background-color: #f8f9fa;
    animation: background 5s infinite alternate;
}

.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.logo .wieprzuga {
    width: 152px;
    height: 44px;
    font-family: 'SofiaProRegular';
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
}

.logo .szescK {
    font-size: 32px;
    font-weight: bold;
    font-family: 'SofiaProBold';
    color: #002cc1;
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.ant-menu-inline-collapsed {
    width: 100%;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 8px !important;
}

.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 24px !important;
    padding-right: 20px !important;
    padding-bottom: 0px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item > a > .icon-external {
    display: none;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
}

.ant-tooltip-placement-right {
    padding-top: 24px;
}
