.nav-text {
    width: 70px;
    height: 19px;
    font-family: 'SofiaProRegular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
}

.ant-menu-item i svg {
    color: blue;
    object-fit: contain;
}

.ant-menu-item:hover {
    background-color: #eeeff1;
}

.ant-menu-item {
    padding-left: 24px !important;
}

.ant-menu-submenu div i svg {
    color: blue;
    object-fit: contain;
}

.ant-menu-submenu {
    border-top: 1px solid #dde2e5;
    margin-top: 16px;
    margin-bottom: 48px;
    padding-left: 2px;
}

.ant-menu-submenu-title {
    height: 48px !important;
    margin-top: 10px !important;
    min-height: 48px;
    display: inline-flex;
    align-items: center;
    padding-right: 16px !important;
    padding-left: 24px !important;
}

.ant-menu-submenu-title .anticon {
    line-height: normal !important;
    margin-right: 24px;
}

.ant-menu-submenu-title .nav-text {
    line-height: normal !important;
}

.ant-menu-submenu-popup {
    padding-top: 0px;
    padding-left: 0px;
}

.ant-submenu-collapse-icon {
    margin-right: 0px !important;
    margin-left: 70px;
}
@media only screen and (max-width: 1199px) {
    .ant-submenu-collapse-icon {
        margin-left: 100px;
    }
}
.ant-menu-submenu-title:hover {
    background-color: #eeeff1;
    margin-right: -1px;
}

.ant-menu-inline-collapsed-tooltip {
    display: none;
}
