.ant-tooltip-content .ant-tooltip-arrow {
  visibility: hidden !important;
}

.ant-tooltip-content .ant-tooltip-inner {
  font-family: "SofiaProRegular";
  font-size: 10px !important;
  border-radius: 4px;
  min-height: unset !important;
  height: 24px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  box-shadow: 0 2px 4px 0 rgba(32, 32, 35, 0.24);
  background-color: #495057;
}

.ant-modal-header {
  display: none;
  border-bottom: none;
}

.ant-modal-body {
  padding-top: 36px;
  padding-left: 32px;
}

.ant-modal-footer {
  display: none;
  border-top: none;
}

.ant-modal-close {
  margin-right: 16px;
  margin-top: 28px;
}

.ant-modal-content {
  border-radius: 8px;
}
