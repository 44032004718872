.ant-calendar-month-panel-body {
    font-family: 'SofiaProRegular' !important;
}

.ant-picker-cell {
    color: #acb5bd !important;
}

.ant-picker-header {
    border-bottom: none !important;
}

.ant-picker-cell:hover {
    color: #002cc1 !important;
    background-color: white !important;
}

.ant-picker-cell-inner:hover {
    background-color: white !important;
}

.ant-picker-cell-selected {
    background-color: unset !important;
    color: #002cc1 !important;
}

.ant-picker-header-super-prev-btn:hover {
    color: #002cc1 !important;
}

.ant-picker-header-super-next-btn:hover {
    color: #002cc1 !important;
}

.ant-calendar-picker-container {
    margin-top: 58px !important;
    top: 0 !important;
}

.ant-picker-year-btn {
    color: #002cc1 !important;
}

.ant-picker-focused {
    border-color: #002cc1;
}

@media only screen and (max-width: 1199px) {
    .ant-picker-dropdown {
        margin-top: -145px !important;
        position: fixed;
        left: 50% !important;
        top: 50% !important;
        margin-left: -140px !important;
    }
}
